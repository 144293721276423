<template>
  <div
    class="px-3 py-3"
    :style="`
      border: 1px solid #555;
            border-radius: 10px;
            ${
              $vuetify.breakpoint.smAndDown
                ? 'width: 400px;bottom: 0px;'
                : 'position: absolute;width: 400px;top: 20%;right: 10px;'
            }`"
  >
    <h1 class="text-center">Any Issues / 문제발생 시</h1>
    <v-layout row justify-center>
      <v-flex col-2>
        <v-img
          alt="phone"
          class="pointer"
          @click="openLink('')"
          height="50"
          contain
          :src="require(`../assets/img/social/ni_phone.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center col-10>
        +66) 095-689-4280 (ไทย)<br />
        +82) 010-8248-5256 (대한민국)
      </v-flex>
      <v-flex @click="openLink('mailto:hellocst209@gmail.com')" col-2>
        <v-img
          alt="gmail"
          class="pointer"
          @click="openLink('')"
          height="48"
          contain
          :src="require(`../assets/img/social/gmail.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center @click="openLink('mailto:hellocst209@gmail.com')" col-10>
        hellocst209@gmail.com
      </v-flex>
      <v-flex @click="openLink('https://www.instagram.com/09somchai/')" col-2>
        <v-img
          alt="instagram icon"
          class="pointer"
          @click="openLink('')"
          height="48"
          contain
          :src="require(`../assets/img/social/ig.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center @click="openLink('https://www.instagram.com/09somchai/')" col-10>
        09somchai
      </v-flex>
      <v-flex @click="openLink('http://line.me/ti/p/~hellocst')" col-2>
        <v-img
          alt="line icon"
          class="pointer"
          @click="openLink('')"
          height="48"
          contain
          :src="require(`../assets/img/social/line.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center @click="openLink('http://line.me/ti/p/~hellocst')" col-10>
        hellocst
      </v-flex>
      <v-flex col-2>
        <v-img
          alt="kakao icon"
          class="pointer"
          @click="openTab('kakao')"
          height="48"
          contain
          :src="require(`../assets/img/social/kakao.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center col-10 @click="openTab('kakao')"> thisdays2 </v-flex>
      <v-flex @click="openLink('https://www.facebook.com/Hellocst')" col-2>
        <v-img
          alt="facebook icon"
          class="pointer"
          @click="openLink('')"
          height="48"
          contain
          :src="require(`../assets/img/social/fb.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center @click="openLink('https://www.facebook.com/Hellocst')" col-10>
        hellocst
      </v-flex>
      <v-flex @click="openLink('')" col-2>
        <v-img
          alt="whatapp icon"
          height="48"
          class="pointer"
          @click="openTab('what app')"
          contain
          :src="require(`../assets/img/social/what_app.png`)"
        >
        </v-img>
      </v-flex>
      <v-flex align-self-center @click="openTab('what app')" col-10>
        +6695-689-4280
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalShow: false
    }
  },
  methods: {
    openLink(link) {
      if (link) {
        window.open(link)
      }
    },
    openTab(app){
      if (app == 'kakao'){
        this.$modal.show('kakao')
      }else if(app == 'what app'){
        this.$modal.show('whatapp')
      }
    }
  }
}
</script>

<style>
</style>
