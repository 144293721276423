<template>
  <div class="fill-height py-3 py-md-10">
    <Loading :loading="isLoading"></Loading>
    <v-container style=" position: relative">
      <v-stepper v-model="step" style="box-shadow: none" alt-labels>
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 1 }"
            :complete="step > 1"
            step="1"
          >
            {{ $t('checkoutStep.login') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 2 }"
            :complete="step > 2"
            step="2"
          >
            {{ $t('checkoutStep.address') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 3 }"
            :complete="step > 3"
            step="3"
          >
            {{ $t('checkoutStep.selectPayment') }}
          </v-stepper-step>
          <v-divider class="border-color mt-10"></v-divider>
          <v-stepper-step
            color="var(--cust-red)"
            :class="{ 'step-active': step == 4 }"
            :complete="step === 4"
            step="4"
          >
            {{ $t('checkoutStep.payment') }}
          </v-stepper-step>
        </v-stepper-header>
        <div style="height: 100%" class="flex-center">
          <div
            v-if="$route.query.status === 'completed'"
            class="text-center flex-center pa-7"
          >
            <v-sheet color="transparent">
              <v-img
                contain
                class="mb-7"
                width="100%"
                max-width="200px"
                :src="imageSuccess"
              ></v-img>
              <h1 class="green--text mb-1">{{ $t('resultPayment.complete') }} !</h1>
              <OrderExport
                v-if="cartOrder"
                :addressBilling="cartOrder.address"
                :inCart="cartOrder.cart.cart_item"
                :sell_rate="cartOrder.sell_rate"
                :vat="cartOrder.vat"
                :deliveryCost="cartOrder.shipping_price"
                :cartDetail="cartOrder.cart"
                :currency="cartOrder.currency"
              ></OrderExport>
            </v-sheet>
          </div>
          <div v-if="$route.query.status === 'checking'"></div>
        </div>
      </v-stepper>
    </v-container>

    <AnyIssue></AnyIssue>
  </div>
</template>

<script>
import OrderExport from '../components/OrderExport'
import Loading from '../components/Loading.vue'
import { mapState } from 'vuex'
import Service from '../services'
import AnyIssue from '../components/AnyIssue.vue'
export default {
  name: 'PaymentResult',
  components: {
    OrderExport,
    AnyIssue,
    Loading
  },
  data: () => ({
    step: 4,
    imageSuccess: require('../assets/img/success-icon.png'),
    isLoading: false,
    cartOrder:null,
  }),
  computed: {
    ...mapState({
      inCart: (state) => state.Cart.inCart,
      cartDetail: (state) => state.Cart.cartDetail,
      currency: (state) => state.Currency.current,
      deliveryCost: (state) => state.Address.shippingCost,
      addressBilling: (state) => state.Address.billingAddress,
      exchangeRate: (state) => state.Currency.exchangeRate,
      vat: (state) => state.Payment.vat,
      order: (state) => state.Order.order
    })
  },
  created() {
    switch (this.$route.query.status) {
      case 'canceled':
        this.$swal.fire({
          ...this.$swalError,
          text: 'Payment failure, please try again'
        })
        this.$router.push({ name: 'Home' })
        break

      case 'completed':
        this.getOrderDetail()
        break
      case 'checking':
        this.checkPaymentStatus()
        break
      default:
        this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async getOrderDetail() {
      this.isLoading = true
      const orderId = this.$route.query.order
      await this.$store.dispatch('Order/onGetOrderDetail', {
        order_id: orderId
      }).then((response)=>{
        this.cartOrder = response
      })
      this.isLoading = false
    },
    backToOrders() {
      clearInterval(this.interval)
      this.$router.push({ name: 'MyOrders' })
    },
    checkPaymentStatus() {
      this.isLoading = true
      const orderId = this.$route.query.order
      if (orderId) {
        Service.payment
          .checkOmiseCharge(orderId)
          .then((res) => {
            this.isLoading = false
            if (res.status === 200) {
              if (res.data.message === 'successful') {
                this.$router.push({
                  name: 'PaymentResult',
                  query: { status: 'completed', order: res.data.order_id }
                })
              }
            } else {
              this.$router.push({
                name: 'PaymentResult',
                query: { status: 'canceled' }
              })
            }
          })
          .catch((error) => {
            this.isLoading = false
            this.$router.push({
              name: 'PaymentResult',
              query: { status: 'canceled' }
            })
          })
      } else {
        this.$router.push({
          name: 'PaymentResult',
          query: { status: 'canceled' }
        })
      }
    }
  }
}
</script>

<style scoped>
.card-success {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background: rgb(255, 252, 243);
  background: linear-gradient(
    180deg,
    rgba(255, 252, 243, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
</style>
